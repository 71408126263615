export const environment = {
  production: false,
  groupName: "--",
  BusinessUnit:"SOUTHERN AFRICA SBU",
  apiUrl: "https://globaldoa-api-dev.azure.chevron.com/api",
  scope:"https://doacon-test.azure.chevron.com/user_impersonation",
  histroyReportUrl:"https://app.powerbi.com/reportEmbed?reportId=b0eda8d0-34a1-43b5-b03e-312120256287&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  logReportUrl:"https://app.powerbi.com/reportEmbed?reportId=87d68b62-5a37-469c-9142-b970d2a39e96&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  integrityReportUrl:"https://app.powerbi.com/reportEmbed?reportId=7a03a480-24ff-4d00-a7b3-2292c787e687&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  masterReportUrl:"https://app.powerbi.com/reportEmbed?reportId=60bd4cf5-a289-44a6-852c-9cfcac0c9f97&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"
  
  
  // apiUrl: "https://aqua3-api-dev.azure.chevron.com/api/v1",
  // clientId: "4d9b760f-9fb3-4e8d-913b-9d5cd637421e",
  // tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  
};
