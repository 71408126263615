import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private secretKey: CryptoKey | null = null;
 
  constructor() {}
 
  // Generate a cryptographic key for AES
  async generateKey(): Promise<void> {
    this.secretKey = await crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      true,
      ['encrypt', 'decrypt']
    );
  }
 
  // Encrypt data
  async encryptData(data: any): Promise<string> {
    if (!this.secretKey) {
      throw new Error('Encryption key not generated');
    }
 
    const encodedData = new TextEncoder().encode(JSON.stringify(data));
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      this.secretKey,
      encodedData
    );
 
    const combinedBuffer = new Uint8Array(iv.length + encryptedBuffer.byteLength);
    combinedBuffer.set(iv);
    combinedBuffer.set(new Uint8Array(encryptedBuffer), iv.length);
 
    return btoa(String.fromCharCode(...combinedBuffer));
  }
 
  // Decrypt data
  async decryptData(encryptedData: string): Promise<any> {
    if (!this.secretKey) {
      throw new Error('Encryption key not generated');
    }
 
    try {
      // Decode the base64-encoded string
      const combinedBuffer = Uint8Array.from(atob(encryptedData), (c) => c.charCodeAt(0));
      const iv = combinedBuffer.slice(0, 12); // Extract IV
      const encryptedBuffer = combinedBuffer.slice(12); // Extract encrypted data
 
      // Decrypt the data
      const decryptedBuffer = await crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: iv,
        },
        this.secretKey,
        encryptedBuffer
      );
 
      // Decode and parse the JSON
      return JSON.parse(new TextDecoder().decode(decryptedBuffer));
    } catch (error) {
      console.error('Decryption failed:', error);
      throw new Error('Failed to decrypt data');
    }
  }
}