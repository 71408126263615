import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment.dev';

@Component({
  selector: 'app-integrityreport',
  templateUrl: './integrityreport.component.html',
  styleUrls: ['./integrityreport.component.css']
})
export class IntegrityreportComponent implements OnInit {
  integrityUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.integrityUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.integrityReportUrl);
  }
}
