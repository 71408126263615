import { Component,OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment.dev';
@Component({
  selector: 'app-historyreport',
  standalone: true,
  imports: [],
  templateUrl: './historyreport.component.html',
  styleUrl: './historyreport.component.css'
})
export class HistoryreportComponent implements OnInit {
  historyUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.historyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.histroyReportUrl);
  }
}