import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ISearchCriteria } from 'src/app/interfaces/search/searchcriteria';
import { ISearchTemp } from 'src/app/interfaces/search/searchtemp';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
import { SearchService } from 'src/app/services/search/search.service';
import { CommonService } from 'src/app/services/common/common.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-temporary-doa',
  templateUrl: './temporary-doa.component.html',
  styleUrl: './temporary-doa.component.css',
})
export class TemporaryDoaComponent {
  selectedFile: File | null = null;
  showElement: boolean;
  temporaryEmail: string;
  form: FormGroup;
  enterTempDOAGridOptions: any;
  isNewRequest: boolean = true;
  isUpdateRequest: boolean = false;
  displayedColumns: string[] = [
    'position',
    'email',
    'name',
    'startDate',
    'endDate',
    'remove',
  ];
  listingsVisible: boolean = false;
  public searchCriteria: ISearchCriteria;
  dataSourceLoading: boolean = false;
  public searchForm: FormGroup;
  public originalDataSource: MatTableDataSource<ISearchTemp>;
  public tableDataSource: MatTableDataSource<ISearchTemp>;
  delegatorShowWarning: boolean = false;
  delegateeShowWarning: boolean = false;
  today: string;
  businessUnit: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public tableDisplayedColumns: string[] = [
    'startDate',
    'endDate',
    'dueTo',
    'toName',
    'to_Division',
    'to_Section',
    'supervisorName',
    'from_Name',
    'createdBy',
    'created',
    'workDays',
    'offDuty',
  ];
  constructor(
    private fb: FormBuilder,
    private searchDOADataService: SearchService,
    private doaService: DelegationOfAuthorityService,
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private printService: PrintService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.showElement = data['showElement'];
    });

    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    const employeeInfo = JSON.parse(
      localStorage.getItem('employeeInfo') || '{}'
    );
    if (employeeInfo && employeeInfo.businessUnit) {
      this.businessUnit = employeeInfo.businessUnit;
    }
    this.route.data.subscribe((data) => {
      this.showElement = data['showElement'];
    });

    this.isNewRequest = this.commonService.isNewRequest ?? true;
    this.isUpdateRequest = this.commonService.isUpdateRequest;
    if (!this.isNewRequest) {
      let redelegationId = this.commonService.tempRedelegationId;
      this.doaService
        .getRedelegations(redelegationId, this.businessUnit)
        .subscribe((data) => {
          if (data) {
            this.form.patchValue({
              reason: data[0].dueTo,
              email: data[0].from,
              ccTo: data[0].cc,
            });
            const rowData = {
              email: data[0].to,
              Name: data[0].toName,
              StartDate: formatDate(data[0].startDate, 'yyyy-MM-dd', 'en'),
              EndDate: formatDate(data[0].endDate, 'yyyy-MM-dd', 'en'),
              editMode: true,
            };
            this.enterTempDOAGridOptions.data.push(rowData);
            this.searchDelegator(data[0].from);
          }
        });
    }

    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: [''],
      workLocation: [''],
      jobTitle: [''],
      department: [''],
      reason: [''],
      ccTo: ['', [this.optionalEmailValidator()]],
    });
    this.initializeGridOptions(this.isNewRequest);

    this.listingsVisible = true;
    this.dataSourceLoading = true;
    this.searchForm = this.fb.group({
      status: ['All'],
      department: [''],
      delegateCAI: [''],
      delegateName: [''],
      assignedReason: ['All'],
      delegatorCAI: [''],
      submittedByCAI: [''],
    });
    this.searchCriteria = this.searchForm.value;
    this.searchCriteria.businessUnit = this.businessUnit;
    this.searchDOADataService
      .getSearchTempDOA(this.searchCriteria)
      .subscribe((data: any) => {
        this.tableDataSource = new MatTableDataSource(data);
        this.originalDataSource = new MatTableDataSource(data);
        this.tableDataSource.paginator = this.paginator;
        this.dataSourceLoading = false;
      });
  }

  initializeGridOptions(isEditable: boolean): void {
    this.enterTempDOAGridOptions = {
      columnDefs: [
        { field: 'SerialNo', displayName: 'S/No' },
        { field: 'Email', displayName: 'Email' },
        { field: 'Name', displayName: 'Name' },
        { field: 'StartDate', displayName: 'Start Date' },
        { field: 'EndDate', displayName: 'End Date' },
        ...(isEditable ? [{ field: 'Remove', displayName: 'Remove' }] : []),
      ],
      data: [],
    };
  }

  addNewRow(isInvalid: boolean): void {
    if (isInvalid) {
      this.commonService.openModal(
        'Message','Please enter correct DOA details before adding another DOA'
      );
    } else {
      const lastRow =
        this.enterTempDOAGridOptions.data[
          this.enterTempDOAGridOptions.data.length - 1
        ];
      const newStartDate = lastRow ? new Date(lastRow.EndDate) : new Date();
      newStartDate.setDate(newStartDate.getDate() + 1);
      this.enterTempDOAGridOptions.data.push({
        CAI: '',
        Name: '',
        StartDate: '',
        EndDate: '',
        editMode: true,
      });
    }
  }

  removeDOARow(row: any): void {
    const index = this.enterTempDOAGridOptions.data.indexOf(row);
    if (index > -1) {
      this.enterTempDOAGridOptions.data.splice(index, 1);
    }
  }

  searchDelegator(email: string): void {
    if (this.form.get('email').invalid) {
      this.form.get('email').markAsTouched();
      return;
    }
    this.doaService.getUserProfile(email).subscribe((data) => {
      if (data) {
        this.form.patchValue({
          name: data.name,
          workLocation: data.workLocation,
          jobTitle: data.jobTitle,
          department: data.department,
        });
      }
    });
  }

  searchDelegatee(email: string): void {
    const isDelegatorMatchingEmail = this.enterTempDOAGridOptions.data.some(
      (row) => row.email === this.form.get('email').value
    );
    this.checkDelegateeEmail(email);
    if (isDelegatorMatchingEmail) {
      this.delegatorShowWarning = true;
      return;
    }
    this.delegatorShowWarning = false;
    this.doaService.getUserProfile(email).subscribe((data) => {
      if (data) {
        const row = this.enterTempDOAGridOptions.data.find(
          (r) => r.email === email
        );
        if (row) {
          row.Name = data.name;
          row.department = data.department;
          row.division = data.division;
          row.section = data.section;
        }
      }
    });
  }

  submitForm() {
    const employeeInfoData = JSON.parse(
      localStorage.getItem('employeeInfo') || '{}'
    );
    const lstTempDOADetails = this.enterTempDOAGridOptions.data.map((row) => ({
      CC_ToEmail: this.form.get('ccTo').value,
      DUE_To: this.form.get('reason').value,
      END_DATE: row.EndDate,
      ENTERED_BY_CAI: employeeInfoData.emailAddress,
      Entered_Date: new Date(),
      FROM_CAI: this.form.get('email').value,
      Modified_By_CAI: employeeInfoData.emailAddress,
      Modified_DATE: new Date(),
      START_DATE: row.StartDate,
      TO_CAI: row.email,
      TO_DEPARTMENT: row.department,
      TO_DIVISION: row.division,
      TO_NAME: row.Name,
      TO_SECTION: row.section,
      Attachments: [],
    }));

    const isFormInvalid = lstTempDOADetails.some(detail => !detail.START_DATE || !detail.END_DATE || !detail.FROM_CAI);
    if (isFormInvalid) {
        return;
    }


    if (this.commonService.tempRedelegationId) {
      this.doaService
        .deleteRedelegation(
          this.commonService.tempRedelegationId,
          this.businessUnit
        )
        .subscribe();
    }
    if (!this.showElement) {
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile!);

      reader.onload = () => {
        const base64FileData = (reader.result as string).split(',')[1];
        const payload = {
          fileType: this.selectedFile?.type,
          fileName: this.selectedFile?.name,
          fileData: base64FileData,
          loggedInUserEmail: employeeInfoData.emailAddress,
          businessUnit: this.businessUnit,
        };
        lstTempDOADetails.forEach((detail) => {
          detail.Attachments.push(payload);
        });
        this.doaService
          .newTempDOA(lstTempDOADetails, this.businessUnit)
          .subscribe((data: any) => {
            if (data) {
              this.router.navigate(['Home']);
            }
          });
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    } else {
      this.doaService
        .newTempDOA(lstTempDOADetails, this.businessUnit)
        .subscribe((data: any) => {
          if (data) {
            this.commonService.openModal(
              'Message','Temporary DOA Submitted Successfully'
            );
            this.router.navigate(['Home']);
          }
        });
    }
  }

  applyFilter(filterValue: string, column: string) {
    this.tableDataSource.filterPredicate = (data: any, filter: string) => {
      const transformedFilter = JSON.parse(filter);
      const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
      return dataValue.includes(transformedFilter[column].toLowerCase());
    };
    const filterObject = { [column]: filterValue };
    this.tableDataSource.filter = JSON.stringify(filterObject);
  }

  optionalEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null; // If the field is empty, it's valid
      }
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value) ? null : { invalidEmail: true };
    };
  }
  checkDelegateeEmail(email: string): boolean {
    const matchingRows = this.enterTempDOAGridOptions.data.filter(
      (row) => row.email === email
    );
    const hasEmptyName = matchingRows.some(
      (row) => !row.name || row.name.trim() === ''
    );

    if (matchingRows.length > 1 && hasEmptyName) {
      this.delegateeShowWarning = true;
      return false;
    }

    this.delegateeShowWarning = false;
    return true;
  }

  handleEmailInputChange(row: any): void {
    if (!row.email || row.email.trim() === '') {
      this.delegateeShowWarning = false;
      this.delegatorShowWarning = false;
    }
  }

  viewLatestPermanentDOA(row) {
    var param = {
      userEmail: row.from,
    };
    this.commonService.params = param;
    this.router.navigate(['/DOA/PermanentDOA']);
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  cancelRequest(): void {
    this.form.reset();
    this.enterTempDOAGridOptions.data = [];
    this.commonService.isNewRequest = true;
    this.isUpdateRequest = false;
    this.router.navigate(['Home']);
  }

  close(): void {
    this.form.reset();
    this.enterTempDOAGridOptions.data = [];
    this.commonService.isNewRequest = true;
    this.isUpdateRequest = false;
    this.router.navigate(['DOA/MyProfile']);
  }

  updateRequest(): void {
    this.isNewRequest = true;
  }

  printDataTable(): void {
    this.printService.printPage();
  }

  DeleteDelegation(): void {
    this.doaService
      .deleteRedelegation(
        this.commonService.tempRedelegationId,
        this.businessUnit
      )
      .subscribe();
    this.cancelRequest();
  }

  hasFutureStartDate(): boolean {
    const today = new Date().toISOString().split('T');
    return this.enterTempDOAGridOptions.data.some(
      (row: any) => row.StartDate > today
    );
  }
}
