import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment.dev';


@Component({
  selector: 'app-logreport',
  standalone: true,
  imports: [],
  templateUrl: './logreport.component.html',
  styleUrl: './logreport.component.css'
})
export class LogreportComponent {
  logUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.logUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.logReportUrl);
  }
}
