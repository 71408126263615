export const environment = {
  production: false,
  groupName: "--",
  BusinessUnit:"SOUTHERN AFRICA SBU",
  apiUrl: "https://globaldoa-api-dev.azure.chevron.com/api",
  scope:"https://doacon-test.azure.chevron.com/user_impersonation"
  
  
  // apiUrl: "https://aqua3-api-dev.azure.chevron.com/api/v1",
  // clientId: "4d9b760f-9fb3-4e8d-913b-9d5cd637421e",
  // tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  
};
